import React from 'react'
import {AdminLayout, ChangeComunCoverages} from 'widgets'
import {setLoading, quitLoading, showError} from 'utils'
import {ComunCoverageService} from 'services'
import { PlanPrevItems } from 'models'
import { Button, Modal, Pagination, Table } from 'components'

const comunCoverages = () =>{
    const [visible, setVisible] = React.useState<boolean>(false)
    const [coverages, setCoverages] = React.useState<PlanPrevItems[]>([])
    const load = async () => {
        try {
            setLoading()
            const res = await ComunCoverageService.get()
            setCoverages(res)
        } catch (error) {
            showError()
        }finally{
            quitLoading()
        }
    }
    React.useEffect(() => {
        load()
    }, [])
    const onClose = () => {
        setVisible(false)
        load()
    }
    return (
        <AdminLayout>
            <Modal
                visible={visible}
                onClose={onClose}
                >
                <ChangeComunCoverages
                    coverages={coverages}
                    onClose={onClose}
                />
            </Modal>
            <div className="plans-and-coverage">
                <div className="container">
                    <Table
                        header={
                            ['#', 'Nombre']
                        }
                        data={coverages.length}
                        title="Coberturas comunes"
                        right={
                            <Button
                                icon="edit"
                                className="primary"
                                small
                                onClick={() => setVisible(true)}
                            />
                        }
                    >
                        {
                            coverages?.map((element, i) => {
                                return(
                                    <tr key={ i }>
                                        <th scope="row">{ element.id }</th>
                                        <td className="ellipsis"> {element.name} </td>
                                    </tr>
                                )
                            })
                        }
                    </Table>
                </div>
            </div>
        </AdminLayout>
    )
}

export default comunCoverages
